import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  useMediaQuery,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import ModalCountdown from "./ModalCountdown";
import { useShopify } from "../hooks";

const ProductPage = (props) => {
  const theme = useTheme();
  const { isOpen, onClose, product } = props;
  const { openCart, checkoutState, optionsToVariant, addVariant } =
    useShopify();
  const [variant, setVariant] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [selectedOptionImage, setSelectOptionImage] = useState(0);

  const youthSizes = ["Youth S", "Youth M", "Youth L", "Youth XL"];
  const voidedColors = ["Military Green", "Indigo", "Heavy Metal"];

  console.log(product);
 

  useEffect(() => {
    const defaultOptionValues = {};
    product.options.forEach((selector) => {
      defaultOptionValues[selector.name] =
        selector.name === "Size" && selector?.values?.length > 1
          ? "pleaseSelect"
          : selector.values[0];
    });
    setSelectedOptions(defaultOptionValues);
  }, []);

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultVariant = product.variants && product.variants[0].id.toString();

  function addToCart() {
    if (selectedOptions?.Size && selectedOptions?.Size === "pleaseSelect") {
      const sizeSelect = document.getElementById("Size");
      if (sizeSelect) sizeSelect.focus();
      return;
    }

    if (variant === "") {
      const lineItemsToAdd = [{ variantId: defaultVariant, quantity: 1 }];
      const checkoutId = checkoutState.id;
      addVariant(checkoutId, lineItemsToAdd);

      openCart();
    } else if (variant) {
      const lineItemsToAdd = [
        { variantId: variant.id.toString(), quantity: 1 },
      ];
      const checkoutId = checkoutState.id;
      addVariant(checkoutId, lineItemsToAdd);

      openCart();
    }
  }

  const handleOptionChange = (event) => {
    const { target } = event;

    setSelectedOptions((prevState) => {
      const newState = { ...prevState };
      newState[target.name] = target.value;
      setVariant(optionsToVariant(product, newState));
      return newState;
    });
  };

  useEffect(() => {
    if (!!variant && product.images.length > 1) {
      const imageIndex = product.images.findIndex(
        (image) => image.id === variant.image.id
      );
      if (imageIndex >= 0) setSelectOptionImage(imageIndex);
    } else if (product.images.length > 1 && !!selectedOptions?.Color) {
      const firstSize = product.options.find((option) => option.name === "Size")
        ?.values[0];

      const tempVariant = optionsToVariant(product, {
        ...selectedOptions,
        Size: firstSize,
      });

      const imageIndex = product.images.findIndex(
        (image) => image.id === tempVariant.image.id
      );
      if (imageIndex >= 0) setSelectOptionImage(imageIndex);
    }
  }, [selectedOptions?.Color]);

  // scroll to image when setSelectOptionImage is called only run if the screen size is greater than 1024
  useEffect(() => {
    if (window.innerWidth > 1024) {
      const image = document.getElementById(selectedOptionImage);
      if (image) {
        image.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [selectedOptionImage]);

  return (
    <>
      <div className="productlanding-container">
        <div className="product-images-container">
          {product?.images?.length > 0 && (
            <Grid item xs={12}>
              <div className="ProductOptions__Carousel-container">
                <Carousel
                  showIndicators={product.images.length > 1}
                  showStatus={false}
                  showThumbs={false}
                  selectedItem={selectedOptionImage}
                  showArrows={true}
                  dynamicHeight={true}
                  className="ProductOptions__Carousel"
                >
                  {product.images.map((image, i) => (
                    <img
                      key={image.id + i}
                      src={image.transformedSrc}
                      alt={`${product.title} product shot`}
                      id={i}
                    />
                  ))}
                </Carousel>
              </div>
            </Grid>
          )}
        </div>
        {window.innerWidth > 1024 ? (
          <div className="product-info-container-new">
            <div className="product-info-new">
              <h2 className="Product__title2">{product.title}</h2>
              {product.endDate && <ModalCountdown date={product.endDate} />}
              <div
                className="Product__description"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />

              {selectedOptions
                ? product.options.map(
                  (option) =>
                    option?.name !== "Title" &&
                    option?.values?.length > 1 && (
                      <div className="Product__option" key={option.name}>
                        <h4 className="Product__option-label">
                          {option.name}
                        </h4>
                        <StyledSelect
                          className="Product__option-select"
                          value={selectedOptions[option.name]}
                          onChange={handleOptionChange}
                          labelId={`${option.name}_label`}
                          id={option.name}
                          name={option.name}
                          variant="outlined"
                          fullWidth
                        >
                          {option.name === "Size" && (
                            <StyledMenuItem
                              value="pleaseSelect"
                              style={{ color: "white" }}
                              key={`${option.name}-pleaseSelect`}
                            >
                              Select size...
                            </StyledMenuItem>
                          )}
                          {/* Scroll to image when selecting */}
                          {option.values.map((value, i) => (
                            <StyledMenuItem
                              value={value}
                              key={`${option.name}-${value}`}
                              onClick={() => {
                                setSelectOptionImage(i);
                              }}
                            >
                              {`${value}`}
                            </StyledMenuItem>
                          ))}
                        </StyledSelect>
                      </div>
                    )
                )
                : null}
              <StyledButton
                size="large"
                variant="contained"
                color="primary"
                className="Product__addToCart"
                onClick={

                  //Check amt sold from Shopify, limited to 100 for "Limited" items
                  (product?.totalInventory <= 2300 &&
                    product.title === ("Subject # Limited Hoodie")) ||
                    (product?.totalInventory <= 2300 &&
                      product.title === ("Subject # Limited T-Shirt")) ||
                    (product?.totalInventory === 0 && product.title ===
                      ("Kyto Character Subject-01 XL Gaming Mousepad")) ||
                    (product?.totalInventory === 0 && product.title ===
                      ("Subject-01 Limited Poster")) ||
                    (product?.totalInventory <= 4800 && product.title ===
                      ("Subject-01 Limited Phone Case")) ||
                    (product?.totalInventory <= 4800 && product.title ===
                      ("Kyto Character Subject-01 Phone Case")) ||
                    (product?.totalInventory === 0 && product.title ===
                      ("Kyto Character Subject-01 Sticker"))
                    ? null
                    : () => addToCart()
                }
              >
                {(product?.totalInventory <= 2300 &&
                  product.title === ("Subject # Limited Hoodie")) ||
                  (product?.totalInventory <= 2300 &&
                    product.title === ("Subject # Limited T-Shirt")) ||
                  (product?.totalInventory === 0 && product.title ===
                    ("Kyto Character Subject-01 XL Gaming Mousepad")) ||
                  (product?.totalInventory === 0 && product.title ===
                    ("Subject-01 Limited Poster")) ||
                  (product?.totalInventory <= 4800 && product.title ===
                    ("Subject-01 Limited Phone Case")) ||
                  (product?.totalInventory <= 4800 && product.title ===
                    ("Kyto Character Subject-01 Phone Case")) ||
                  (product?.totalInventory === 0 && product.title ===
                    ("Kyto Character Subject-01 Sticker"))
                  ? "SOLD OUT"
                  : "ADD TO CART"}
              </StyledButton>
            </div>
          </div>
        ) : (
          <div className="Product__info">
            <h2 className="Product__title2">{product.title}</h2>
            {product.endDate && <ModalCountdown date={product.endDate} />}
            <div
              className="Product__description"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
            />

            {selectedOptions
              ? product.options.map(
                (option) =>
                  option?.name !== "Title" &&
                  option?.values?.length > 1 && (
                    <div className="Product__option" key={option.name}>
                      <h4 className="Product__option-label">{option.name}</h4>
                      <StyledSelect
                        className="Product__option-select"
                        value={selectedOptions[option.name]}
                        onChange={handleOptionChange}
                        labelId={`${option.name}_label`}
                        id={option.name}
                        name={option.name}
                        variant="outlined"
                        fullWidth
                      >
                        {option.name === "Size" && (
                          <StyledMenuItem
                            value="pleaseSelect"
                            style={{ color: "white" }}
                            key={`${option.name}-pleaseSelect`}
                          >
                            Select size...
                          </StyledMenuItem>
                        )}
                        {option.values.map((value) => (
                          <StyledMenuItem
                            value={value}
                            key={`${option.name}-${value}`}
                          >
                            {`${value}`}
                          </StyledMenuItem>
                        ))}
                      </StyledSelect>
                    </div>
                  )
              )
              : null}
            <StyledButton
              size="large"
              variant="contained"
              color="primary"
              className="Product__addToCart"
              onClick={

                //Check amt sold from Shopify, limited to 100 for "Limited" items
                (product?.totalInventory <= 2300 &&
                  product.title === ("Subject # Limited Hoodie")) ||
                  (product?.totalInventory <= 2300 &&
                    product.title === ("Subject # Limited T-Shirt")) ||
                  (product?.totalInventory === 0 && product.title ===
                    ("Kyto Character Subject-01 XL Gaming Mousepad")) ||
                  (product?.totalInventory === 0 && product.title ===
                    ("Subject-01 Limited Poster")) ||
                  (product?.totalInventory <= 4800 && product.title ===
                    ("Subject-01 Limited Phone Case")) ||
                  (product?.totalInventory <= 4800 && product.title ===
                    ("Kyto Character Subject-01 Phone Case")) ||
                  (product?.totalInventory === 0 && product.title ===
                    ("Kyto Character Subject-01 Sticker"))
                  ? null
                  : () => addToCart()
              }
            >
              {(product?.totalInventory <= 2300 &&
                product.title === ("Subject # Limited Hoodie")) ||
                (product?.totalInventory <= 2300 &&
                  product.title === ("Subject # Limited T-Shirt")) ||
                (product?.totalInventory === 0 && product.title ===
                  ("Kyto Character Subject-01 XL Gaming Mousepad")) ||
                (product?.totalInventory === 0 && product.title ===
                  ("Subject-01 Limited Poster")) ||
                (product?.totalInventory <= 4800 && product.title ===
                  ("Subject-01 Limited Phone Case")) ||
                (product?.totalInventory <= 4800 && product.title ===
                  ("Kyto Character Subject-01 Phone Case")) ||
                (product?.totalInventory === 0 && product.title ===
                  ("Kyto Character Subject-01 Sticker"))
                ? "SOLD OUT"
                : "ADD TO CART"}
            </StyledButton>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductPage;

const CloseIcon = styled(Close)`
  font-size: 2em;
  color: white;
`;

const StyledButton = styled(Button)`
  border-radius: 0;
  width: 100%;
  margin-top: 15px;
  height: 54px !important;

  .MuiButton-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: white;

    @media (min-width: 960px) {
      font-size: 16px;
    }

    @media (min-width: 1280px) {
      font-size: 20px;
    }
  }
`;

const StyledSelect = styled(Select)`
  height: 40px;
  margin: 0 !important;
  background: rgba(0, 0, 0, 0.1) !important;

  .MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
    color: var(--color-text) !important;
  }

  .MuiSelect-outlined {
    border-radius: 0px;
  }

  .MuiInputBase-input {
    font-size: 15px !important;

    @media (min-width: 960px) {
      font-size: 17px !important;
    }
  }

  @media (min-width: 960px) {
    height: 55px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  height: 35px !important;
  font-size: 17px !important;
  color: black !important;
`;
